<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 gap-6 text-2xl font-medium">Compliances</div>
    <div class="flex gap-8">
      <ComplianceListMA
        :heading="'Audits'"
        :items="compliances['audits']"
        :loader="loader"
        :key="compliances['audit']"
      />
      <ComplianceListMA
        :heading="'Risk Assessment'"
        :items="compliances['risk-assessment']"
        :loader="loader"
        :key="compliances['risk-assessment']"
      />
      <ComplianceListMA
        :heading="'Policies'"
        :items="compliances['policies']"
        :loader="loader"
        :key="compliances['policies']"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import ComplianceListMA from "@/components/newUi/Compliance/ComplianceListMA.vue";

const store = useStore();

const loader = ref(false);

const compliances = computed(() => {
  return store.getters["maComplianceCalender/getAllComplianceItems"];
});

const fetchAllCompliances = async () => {
  loader.value = true;
  await store
    .dispatch("maComplianceCalender/fetchAllCompliances", {
      practiceId: localStorage.getItem("practice"),
    })
    .then(() => {
      loader.value = false;
    });
};
onMounted(fetchAllCompliances());
</script>

<style scoped></style>
